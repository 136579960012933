var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 16 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"课程目录","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'pid',
                            {  
                              initialValue: _vm.pid,
                              rules: [{ required: true, message: '请选择！' }] },
                  
              ]),expression:"[\n                'pid',\n                            {  \n                              initialValue: pid,\n                              rules: [{ required: true, message: '请选择！' }] },\n                  \n              ]"}],attrs:{"changeOnSelect":true,"placeholder":"","options":_vm.useableTree,"fieldNames":{
                label: 'name',
                value: 'id',
                children: 'children',
              }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"课程名称","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            { 
                              initialValue: _vm.detail.name,
                              rules: [{ required: true, message: '请输入！' }] },
                        ]),expression:"[\n                            'name',\n                            { \n                              initialValue: detail.name,\n                              rules: [{ required: true, message: '请输入！' }] },\n                        ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"课程介绍","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'remark',
                            {
                              initialValue: _vm.detail.remark,
                               rules: [{ required: true, message: '请输入！' }] },
                        ]),expression:"[\n                            'remark',\n                            {\n                              initialValue: detail.remark,\n                               rules: [{ required: true, message: '请输入！' }] },\n                        ]"}],attrs:{"placeholder":"","auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"课程标签"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'label',
                            {
                              initialValue: _vm.detail.label,
                            }
                        ]),expression:"[\n                            'label',\n                            {\n                              initialValue: detail.label,\n                            }\n                        ]"}],attrs:{"placeholder":"多个标签按照逗号分隔"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"排序值"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'top',
                            {
                              initialValue: _vm.detail.top,
                            }
                        ]),expression:"[\n                            'top',\n                            {\n                              initialValue: detail.top,\n                            }\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"排序值越大越靠前，排序值相同，按照更新时间排序"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"课程封面"}},[_c('Cover',{attrs:{"cover":_vm.cover},on:{"change":payload => _vm.cover = payload}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"章节","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('Chapters',{attrs:{"list":_vm.chapters},on:{"change":payload => _vm.chapters = payload}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }