<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="课程目录" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <a-cascader
                :changeOnSelect="true"
                placeholder
                v-decorator="[
                  'pid',
                              {  
                                initialValue: pid,
                                rules: [{ required: true, message: '请选择！' }] },
                    
                ]"
                :options="useableTree"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="课程名称" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <a-input
                v-decorator="[
                              'name',
                              { 
                                initialValue: detail.name,
                                rules: [{ required: true, message: '请输入！' }] },
                          ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="课程介绍" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <a-textarea
                placeholder
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                              'remark',
                              {
                                initialValue: detail.remark,
                                 rules: [{ required: true, message: '请输入！' }] },
                          ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="课程标签">
              <a-input
                placeholder="多个标签按照逗号分隔"
                v-decorator="[
                              'label',
                              {
                                initialValue: detail.label,
                              }
                          ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="排序值">
              <a-input-number
                style="width: 100%"
                placeholder="排序值越大越靠前，排序值相同，按照更新时间排序"
                v-decorator="[
                              'top',
                              {
                                initialValue: detail.top,
                              }
                          ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="课程封面">
              <Cover :cover="cover" @change="payload => cover = payload" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="章节" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
              <Chapters :list="chapters" @change="payload => chapters = payload" />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import Cover from "./components/cover.vue";
import Chapters from "./components/chapters.vue";
import study from "@/mixins/study";

import request from "@/api/request";

function fetchDetail(params) {
  return request({
    url: "/office-service/study/course/query/" + params.id
  });
}

function edit(data) {
  return request({
    url: "/office-service/study/course/update",
    method: "post",
    data
  });
}

export default {
  name: "courseEdit",

  mixins: [study],

  components: {
    Cover,
    Chapters
  },

  data() {
    return {
      pid: [],
      detail: {},
      form: this.$form.createForm(this),
      cover: {},
      chapters: [],

      loading: false
    };
  },

  activated() {
    const { id } = this.$route.query;
    if (this.id === id) return;
    this.id = id;
    const hide = this.$message.loading("加载中...", 0);

    this.pid = [];
    this.detail = {};
    this.cover = {};
    this.chapters = [];

    fetchDetail({
      id
    })
      .then(res => {
        console.log("detail", res);
        this.pid = [res.pid];
        this.findFather(this.pid, res.pid);

        this.detail = res || {};
        this.cover = res.cover || {};
        this.chapters = Array.isArray(res.chapters)
          ? res.chapters.map(item => {
              return {
                ...item,
                key: item.id,
                fileName: item.attachment ? item.attachment.name : "",
                progress: 100,
                uploadKey: item.attachment ? item.attachment.completePath : ""
              };
            })
          : [];
      })
      .finally(() => {
        hide();
      });
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          if (!this.cover.id) {
            this.$message.error("请上传封面");
            return;
          }
          if (this.chapters.length === 0) {
            this.$message.error("请上传章节");
            return;
          }

          let uploading = false;
          this.chapters
            .filter(item => item.type === "course")
            .forEach(item => {
              if (item.progress < 100) {
                uploading = true;
              }
            });

          if (uploading) {
            this.$message.error("请等待文件上传成功");
          } else {
            console.log("this.chaps", this.chapters);

            this.loading = true;
            edit({
              ...this.detail,
              ...values,
              pid: values.pid[values.pid.length - 1],
              cover: this.cover.id,
              chapters: this.chapters.map(item => {
                return {
                  ...item,
                  attachment: item.attachment
                    ? item.attachment.id
                    : item.uploadKey
                };
              })
            })
              .then(() => {
                this.$close(this.$route.path);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
}

.center {
  padding: 40px;
}
</style>
